import React, { Component, useContext, useEffect } from 'react';
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tab,
    TabList,
  } from "@fluentui/react-components";
import { EditorContext } from './Editor';

const Toolbar = () => {
    const styles = {
        position: "absolute",
        top: "56px",
        height: "42px",
        left: "56px",
        right: "300px",
        backgroundColor:"#1c1c1c",
        borderBottom: "1px solid #303030",
        display: "flex",
        padding: "4px",
        justifyContent: "space-between",
        boxSizing: "border-box",
    };

    const {mode, setMode} = useContext(EditorContext);

        return (<div>
            <div className="Toolbar" style={styles}>
            <Menu >
            <MenuTrigger disableButtonEnhancement>
            <MenuButton size='small'>Camera</MenuButton>
            </MenuTrigger>

            <MenuPopover>
            <MenuList>
                <MenuItem>Another Camera</MenuItem>
                <MenuItem>Perspective Camera</MenuItem>
            </MenuList>
            </MenuPopover>
        </Menu>

                <TabList  defaultSelectedValue="tab1" size="medium" appearance="subtle" onTabSelect={(e, d) => {
                    switch(d.value) {
                        case "tab1":
                            setMode("editor");
                            break;
                        case "tab2":
                            setMode("render");
                            break;
                        default:
                            setMode("editor");
                    }
                }}>
                    <Tab value="tab1">Editor</Tab>
                    <Tab value="tab2">Render</Tab>
                </TabList>
                </div>  
                </div>);
}
 
export default Toolbar;