import { Input, useId, InputProps, Slider, Label, Button } from '@fluentui/react-components';
import { ArrowFit16Regular } from '@fluentui/react-icons';
import { CgScrollV } from 'react-icons/cg';
import React, { Component, useState } from 'react';
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from 'react-complex-tree';

import './Editor.css';
import 'react-complex-tree/lib/style-modern.css';
import Panel from './Panel';

const Handle = ({value, setValue}) => {

    const onMouseMove = (event) => {
        event.preventDefault();
        console.log("mousemove");
        
        
        // const { left, width } = ref.current.getBoundingClientRect();
         const x = event.clientY - value;
        // const percent = (x / width) * 100;
        // setPercent(percent);
    
        // const newValue = percentToValue(percent, 0, 100).toFixed(0);
         setValue(x);
      };
    
      const onMouseUp = (event) => {
        event.preventDefault();
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };
    
      const onMouseDown = (event) => {
        event.preventDefault();
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
      };

    return (
        <CgScrollV onMouseDown={onMouseDown} cursor="ns-resize"/>
    );
}

const Field = () => {
    const smallId = useId("input-small");
    const [value, setValue] = useState(100);

    const inp = <Input style={{margin: "0px 2px"}} type="number" id={smallId} contentAfter={<Handle value={value} setValue={setValue}/>} value={value} onChange={handleChange}/>

    const handleChange = (event) => {
      console.log(event.target.value);
      setValue(event.target.value);
    };

    return (
      <div>
        {inp}
      </div>
        
    );
}

const Vector = () => {
    const smallId = useId("input-small");
    const [value, setValue] = useState([]);

    const onChange: InputProps["onChange"] = (ev, data) => {

        if (data.value.length <= 20) {
          setValue(data.value);
        }
      };

    return (
      <>
      <Label>Vector</Label>
      <div style={{display: "flex"}}>
      <Field/><Field/><Field/>
      </div>
      </>
    );
}

const Range = () => {
    const smallId = useId("input-small");
    const [value, setValue] = useState(0);

    const onChange: InputProps["onChange"] = (ev, data) => {

        if (data.value.length <= 20) {
          setValue(data.value);
        }
      };

    return (
      <div style={{display: "flex"}}>
        <Label>Range</Label>
        <Slider defaultValue={50} style={{width: "100%"}}/>
        <Field/>
      </div>

    );
}

const Right = () => {
        const smallId = useId("input-small");
        const styles = {
            position: "absolute",
            top: "56px",
            bottom: 0,
            right: 0,
            width: "300px",
            backgroundColor:"#1c1c1c",
            borderLeft: "1px solid #303030",
            boxSizing: "border-box",
        };
        const [items, setItems] = useState({
          root: {
            index: 'root',
            canMove: true,
            isFolder: true,
            children: ['child1', 'child2', 'child3', 'child4'],
            data: 'Root item',
            canRename: true,
          },
          child1: {
            index: 'child1',
            canMove: true,
            isFolder: true,
            children: [],
            data: 'Child item 1',
            canRename: true,
          },
          child2: {
            index: 'child2',
            canMove: true,
            isFolder: true,
            children: [],
            data: 'Child item 2',
            canRename: true,
          },
          child3: {
            index: 'child3',
            canMove: true,
            isFolder: true,
            children: [],
            data: 'Child item 3',
            canRename: true,
          },
          child4: {
            index: 'child4',
            canMove: true,
            isFolder: true,
            children: [],
            data: 'Child item 4',
            canRename: true,
          },
        });

        const addItem = (event) => {
          const newItems = {
            ...items,
            child5: {
              index: 'child5',
              canMove: true,
              isFolder: true,
              children: [],
              data: 'Child item 5',
              canRename: true,
            },
          };
          newItems.root.children.push('child5');
          setItems(newItems);

              console.log(newItems);
        }
        return (<div>
            <div className="Right" style={styles}>
              <Panel title="Scene">
              
              <UncontrolledTreeEnvironment
              dataProvider={new StaticTreeDataProvider(items, (item, data) => ({ ...item, data }))}
              getItemTitle={item => item.data}
              viewState={{}}
              canDragAndDrop={true}
              canReorderItems={true}
              canDropOnFolder={true}
              canDropOnNonFolder={true}
              updateViewState={viewState => console.log(viewState)}
              onDrop={(item, expanded) => { console.log(item, expanded) }}
            >
              <div
      className="rct-dark"
      style={{ color: '#e3e3e3' }}
    >
      <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
    </div>
            </UncontrolledTreeEnvironment>

            </Panel>

            
            <Panel title="Transform">
            <Vector/>
            <Range/>
            </Panel>

            <Panel title="Empty Panel">
              <Button onClick={addItem}>Add</Button>
            </Panel>
            </div>
            
        </div>);
}
 
export default Right;
