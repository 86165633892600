
import {
    Divider,
    makeStyles,
    shorthands,
    Tab,
    TabList,
  } from "@fluentui/react-components";
  import * as React from "react";
    import {TbLayoutDashboard} from "react-icons/tb";
    import {BiUpload} from "react-icons/bi";
    import {VscLibrary} from "react-icons/vsc";
    import {HiOutlineSparkles} from "react-icons/hi";
  
  const useStyles = makeStyles({
    root: {
      //alignItems: "flex-start",
      //display: "flex",
      //flexDirection: "column",
      //justifyContent: "flex-start",
      ...shorthands.padding("20px", "20px"),
      rowGap: "30px",
    },
  });

const Sidebar = () => {
    const styles = {
        position: "absolute",
        top: "57px",
        bottom: 0,
        left: 0,
        width: "288px",
        backgroundColor:"#1c1c1c",
        borderRight: "1px solid #303030",
    };

    const classes = useStyles();

    return ( <div>
        <div className="Sidebar" style={styles}>
            <div className={classes.root}>

            <TabList defaultSelectedValue="tab2" appearance="subtle" vertical>
                <Tab value="tab1" icon={<TbLayoutDashboard/>}>Projects</Tab>
                <Tab value="tab2" icon={<BiUpload/>}>Uploads</Tab>
                <Tab value="tab3" icon={<VscLibrary/>}>Library</Tab>
                <Tab value="tab4" icon={<HiOutlineSparkles/>}>Templates</Tab>
            </TabList>
            </div>
        </div>
        
    </div> );
}

export default Sidebar;