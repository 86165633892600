// Import the functions you need from the SDKs you need
import { useState, useEffect, createContext, useContext } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWhU-zn0H2mOwLkn0O_6PeDBGmI-JOKwc",
  authDomain: "packshot-fa937.firebaseapp.com",
  projectId: "packshot-fa937",
  storageBucket: "packshot-fa937.appspot.com",
  messagingSenderId: "709224435868",
  appId: "1:709224435868:web:967b0524e4d7e74470dd59",
  measurementId: "G-D5PQ0WTPP9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();