import { useState, useRef, useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineMinusSm } from "react-icons/hi";
import "./Panel.css";

const Panel = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const contentRef = useRef(null);
  
    const handleTitleClick = () => {
      setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (contentRef.current) {
          contentRef.current.style.height = isExpanded
            ? `${contentRef.current.scrollHeight}px`
            : '0';
        }
      }, [isExpanded]);
  
    return (
      <div className="foldable-panel">
        <div className="title-bar" onClick={handleTitleClick}>
            <span className="title-text">{title}</span>
            <span className="folded-indicator">{isExpanded ? <HiOutlineMinusSm/> : <FiChevronDown/>}</span>
        </div>
        <div className="panel-content-wrapper" ref={contentRef}>
            <div className="panel-content">{children}</div>
        </div>
      </div>
    );
  };

export default Panel