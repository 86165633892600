import React, { Component, useState, useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PathTracingSceneGenerator, PathTracingRenderer, PhysicalPathTracingMaterial, PhysicalCamera } from 'three-gpu-pathtracer';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';


const Viewport = () => {

  let container = document.getElementById( 'scene' );
  container.style.left = "56px";
  container.style.right = "300px";
  container.style.top = "98px";
  container.style.bottom = "0px";
  container.style.position = "absolute";

  let renderer, controls, pathTracer, blitQuad, camera, scene, samplesEl;

  let tiles = 1;
  let resolutionScale = Math.max( 1 / window.devicePixelRatio, 0.5 );

  // adjust performance parameters for mobile
  // const aspectRatio = container.innerWidth / container.innerHeight;
  // if ( aspectRatio < 0.65 ) {

  //   resolutionScale = 1 / window.devicePixelRatio;
  //   tiles = 3;

  // }

  init();

  async function init() {

    // init renderer, camera, controls, scene
    renderer = new THREE.WebGLRenderer( { antialias: true } );
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setClearColor( 0, 0 );
    container.appendChild( renderer.domElement );

    camera = new PhysicalCamera( 25, 1, 0.025, 500 );
    camera.position.set( 8, 9, 24 );

    controls = new OrbitControls( camera, renderer.domElement );
    controls.target.y = 10;
    controls.update();

    scene = new THREE.Scene();
    scene.backgroundBlurriness = 0.05;

    // init path tracer
    pathTracer = new PathTracingRenderer( renderer );
    pathTracer.material = new PhysicalPathTracingMaterial();
    pathTracer.material.filterGlossyFactor = 0.5;
    pathTracer.material.backgroundBlur = 0.05;
    pathTracer.tiles.set( tiles, tiles );
    pathTracer.camera = camera;

    blitQuad = new FullScreenQuad( new THREE.MeshBasicMaterial( {
      map: pathTracer.target.texture,
      blending: THREE.CustomBlending,
    } ) );

    controls.addEventListener( 'change', () => {

      pathTracer.reset();

    } );

    // load the envmap and model
    const envMapPromise = new RGBELoader()
      .loadAsync( 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/syferfontein_1d_clear_1k.hdr' )
      .then( texture => {

        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.background = texture;
        scene.environment = texture;
        pathTracer.material.envMapInfo.updateFrom( texture );

      } );

    const generator = new PathTracingSceneGenerator();
    const gltfPromise = new GLTFLoader()
      .loadAsync( 'https://firebasestorage.googleapis.com/v0/b/packshot-fa937.appspot.com/o/uploads%2Fiphone_14_pro_max.glb?alt=media&token=52816c92-8f94-4d66-ae3a-1bfff13baebe' )
      //.loadAsync('https://firebasestorage.googleapis.com/v0/b/packshot-fa937.appspot.com/o/uploads%2Fscene%20(1).gltf?alt=media&token=66b82080-6a8a-49e6-b0d2-dc39d6c42959')
      .then( gltf => {

        const cube = new THREE.BoxGeometry( 50, 0.1, 50 );
        const cubeMesh = new THREE.Mesh( cube, new THREE.MeshBasicMaterial( { color: 0xffffff } ) );

        const light = new THREE.RectAreaLight( 0xffffff, 50, 10, 10 );
        light.position.set( 10, 15, 10 );
        light.lookAt( 0, 0, 0 );
        gltf.scene.add( light );

        gltf.scene.add( cubeMesh );

        return generator.generate( gltf.scene );

      } )
      .then( result => {

        scene.add( result.scene );

        const { bvh, textures, materials, lights } = result;
        const geometry = bvh.geometry;
        const material = pathTracer.material;

        material.bvh.updateFrom( bvh );
        material.attributesArray.updateFrom(
          geometry.attributes.normal,
          geometry.attributes.tangent,
          geometry.attributes.uv,
          geometry.attributes.color,
        );
        material.materialIndexAttribute.updateFrom( geometry.attributes.materialIndex );
        material.textures.setTextures( renderer, 2048, 2048, textures );
        material.materials.updateFrom( materials, textures );
        material.lights.updateFrom( lights );
        material.lightCount = lights.length;

        //generator.dispose();

      } );

    // wait for the scene to be rady
    await Promise.all( [ gltfPromise, envMapPromise ] );

    //document.getElementById( 'loading' ).remove();
    window.addEventListener( 'resize', onResize );

    onResize();
    animate();

  }

  function onResize() {

    // update rendering resolution
    const w = container.offsetWidth;
    const h = container.offsetHeight;
    const scale = resolutionScale;
    const dpr = window.devicePixelRatio;

    pathTracer.setSize( w * scale * dpr, h * scale * dpr );
    pathTracer.reset();

    renderer.setSize( w, h );
    renderer.setPixelRatio( window.devicePixelRatio * scale );

    const aspect = w / h;
    camera.aspect = aspect;
    camera.updateProjectionMatrix();

  }

  function animate() {

    requestAnimationFrame( animate );

    camera.updateMatrixWorld();

    //pathTracer.update();

    if ( pathTracer.samples < 1 ) {

      renderer.render( scene, camera );

    }

    

    renderer.autoClear = false;
    blitQuad.material.map = pathTracer.target.texture;
    blitQuad.render( renderer );
    renderer.autoClear = true;

    //console.log( pathTracer.samples );

  }
}

export default Viewport;
