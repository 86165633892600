import React, { useRef, useState } from "react"
import { useAuth } from "../Contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { FcGoogle } from "react-icons/fc";
import { BiLogIn } from "react-icons/bi";
import {
    makeStyles,
    shorthands,
    useId,
    Input,
    Label,
    Button,
  } from "@fluentui/react-components";

  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      maxWidth: "400px",
      // Stack the label above the field (with 2px gap per the design system)
      "> div": {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("2px"),
      },
    },
  });

export default function Login() {
  const styles = useStyles();
  const emailId = useId("input-email");
  const passwordId = useId("input-password");

  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
    const { loginWithGoogle } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  async function handleGoogleLogin(e) {
    e.preventDefault()

    try {
        setError("")
        setLoading(true)
        await loginWithGoogle()
        history.push("/")
    } catch {
        setError("Failed to log in")
    }

    setLoading(false)
    }

  return (
    <>
    <div className={styles.root}>
      <div>
        <Label htmlFor={emailId}>Email input</Label>
        <Input type="email" id={emailId} ref={emailRef} required/>
      </div>

      <div>
        <Label htmlFor={passwordId}>Password input</Label>
        <Input type="password" defaultValue="password" id={passwordId} />
      </div>

      <Button icon={<BiLogIn/>} disabled={loading} onClick={handleSubmit}>
        Login
      </Button>

      <Button icon={<FcGoogle/>} disabled={loading} onClick={handleGoogleLogin}>
        Google
      </Button>
    </div>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </>
  )
}