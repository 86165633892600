import React, { useEffect, useState } from 'react';
import Header from './Header';
import Left from './Left';
import Right from './Right';
import Toolbar from './Toolbar';
import Viewport from './Viewport';

export const EditorContext = React.createContext();

const Editor = () => {
    const [mode, setMode] = useState('editor');

    useEffect(() => {
        console.log(mode);
    }, [mode]);

    return (
        <EditorContext.Provider value={{ mode, setMode }}>
        <div className="editor">
            <Header />
            <Left />
            <Right />
            <Toolbar />
            <Viewport />
        </div>
       </EditorContext.Provider>
    );
}

export default Editor;