import React, { Component, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "./Contexts/AuthContext";
import { auth } from './firebase';
import Login from './Auth/Login';
import { Button, ButtonProps } from "@fluentui/react-components";
import Editor from './Editor/Editor';
import Main from './Dashboard/Main';

const App = () => {
 
    return (
      <Router>
      <AuthProvider>
        <Switch>
          <PrivateRoute exact path="/" component={Main} />
          {/* <PrivateRoute path="/update-profile" component={UpdateProfile} /> */}
          <Route path="/signup" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/editor" component={Editor} />
          {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
        </Switch>
      </AuthProvider>
    </Router>
      
    );
}

export default App;
