import React, { Component } from 'react';
import {
    useId,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  ToolbarGroup,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Avatar,
  Button,
  Badge,
  Subtitle2,
  Input,
  Title3,
  ToolbarProps,
} from "@fluentui/react-components";
import {
    Search24Regular,
  Cube24Regular,
  TextFont24Regular,
  MoreHorizontal24Filled,
  Cube24Filled,
  ArrowUndo24Regular,
  ArrowRedo24Regular,
} from "@fluentui/react-icons";
import { useAuth } from "../Contexts/AuthContext"



export const Header = () => {
    const afterId = useId("content-after");
    const { currentUser, logout } = useAuth();
    console.log(currentUser.photoURL);
    const styles = {
        position: "absolute",
        top: 0,
        height: "48px",
        left: 0,
        right: 0,
        backgroundColor:"#1c1c1c",
        padding: "4px",
        borderBottom: "1px solid #303030",
    };
    return (
      <div>
        <div className="Header" style={styles}>

              <Toolbar aria-label="Default"  style={{justifyContent: "space-between"}}>
              <ToolbarGroup role="presentation">
              <Cube24Filled/>
              <Subtitle2>Packshot</Subtitle2>
              <Badge appearance="tint">Beta v0.1.0</Badge>
              </ToolbarGroup>

              <ToolbarGroup role="presentation">
              <Input
                contentAfter={<Search24Regular aria-label="Enter by voice" />}
                id={afterId}
                placeholder="Search"
                style={{marginRight: "7px"}}
                />
              <Menu>
                <MenuTrigger>
                <Avatar
                  name="Katri Athokas"
                  active="active"
                  image={{
                    src: currentUser.photoURL,
                    referrerpolicy: "no-referrer"
                  }}
                />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                  <MenuItem>My Profile</MenuItem>
                    <MenuItem>Settings </MenuItem>
                    <MenuItem>Dark </MenuItem>
                    <MenuItem>Log Out </MenuItem>
                    <MenuItem onClick={()=>{logout()}}>Log Out </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              </ToolbarGroup>
              
            </Toolbar>
        </div>
      </div>
    );
  }