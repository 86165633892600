import React, { Component } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import './Header.css';
import {
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  ToolbarGroup,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Avatar,
  Button,
  ToolbarProps,
} from "@fluentui/react-components";
import {
  WeatherSunny24Regular,
  Cube24Regular,
  TextFont24Regular,
  MoreHorizontal24Filled,
  MoreVertical24Regular,
  ArrowUndo24Regular,
  ArrowRedo24Regular,
} from "@fluentui/react-icons";

const Header = () => {
    const styles = {
        position: "absolute",
        top: 0,
        height: "56px",
        left: 0,
        right: 0,
        backgroundColor:"#1c1c1c",
        padding: "4px",
        borderBottom: "1px solid #303030",
        boxSizing: "border-box",
    };
    return (
      <div>
        <div className="Header" style={styles}>

              <Toolbar aria-label="Default"  style={{justifyContent: "space-between"}}>
              <ToolbarGroup role="presentation">
              <Menu>
                <MenuTrigger>
                <ToolbarButton
                aria-label="More"
                icon={<MoreVertical24Regular />}
                />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem>Back </MenuItem>
                    <MenuItem>Reset</MenuItem>
                    <MenuItem disabled>Open File</MenuItem>
                    <MenuItem>Open Folder</MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              <ToolbarButton
                aria-label="Decrease Font Size"
                icon={<ArrowUndo24Regular />}
              />
              <ToolbarButton
                aria-label="Decrease Font Size"
                icon={<ArrowRedo24Regular />}
              />
              </ToolbarGroup>

              <ToolbarGroup role="presentation">
              <ToolbarButton
                aria-label="Increase Font Size"
                //appearance="primary"
                icon={< Cube24Regular/>}
              />
              <ToolbarButton
                aria-label="Decrease Font Size"
                icon={<WeatherSunny24Regular />}
              />
              <ToolbarButton aria-label="Reset Font Size" icon={<TextFont24Regular />} />
              <ToolbarDivider />
              <Menu>
                <MenuTrigger>
                  <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem>New </MenuItem>
                    <MenuItem>New Window</MenuItem>
                    <MenuItem disabled>Open File</MenuItem>
                    <MenuItem>Open Folder</MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              </ToolbarGroup>

              <ToolbarGroup role="presentation">
              <Button 
                appearance='primary'
                aria-label="Increase Font Size"
                style={{marginRight: "7px"}}
              >Share</Button>
              <Menu>
                <MenuTrigger>
                <Avatar
                  name="Katri Athokas"
                  active="active"
                  image={{
                    src: useAuth().currentUser.photoURL,
                    referrerPolicy: "no-referrer"
                  }}
                />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem>My Profile</MenuItem>
                    <MenuItem>Settings </MenuItem>
                    <MenuItem>Dark </MenuItem>
                    <MenuItem>Log Out </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              </ToolbarGroup>
              
            </Toolbar>
        </div>
      </div>
    );
}

export default Header;