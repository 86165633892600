import React, { Component } from 'react';
import { Button, ButtonProps } from "@fluentui/react-components";
import Sidebar from './Sidebar';
import {Header} from './Header';
import Home from './Home';

class Main extends Component {
    constructor(props) {
        super(props);
    }
    state = { name: "React" }
    render() { 
        return ( <div>
            <Header/>
            <Sidebar/>
            <Home/>
        </div> );
    }
}
 
export default Main;