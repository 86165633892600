import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  Divider, Title2,
} from "@fluentui/react-components";
import {
    makeStyles,
    shorthands,
    Button,
    Caption1,
    tokens,
    Text,
    Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Tab,
    TabList,
  } from "@fluentui/react-components";
  import {
    Card,
    CardHeader,
    CardPreview,
    CardProps,
  } from "@fluentui/react-components/unstable";

  import { MoreHorizontal20Filled, Delete24Regular, DesktopEdit24Regular } from "@fluentui/react-icons";
import { Link, Redirect } from 'react-router-dom';

  const resolveAsset = (asset: string) => {
    const ASSET_URL =
      "https://raw.githubusercontent.com/microsoft/fluentui/master/packages/react-components/react-card/stories/assets/";
  
    return `${ASSET_URL}${asset}`;
  };

const useStyles = makeStyles({
    main: {
      ...shorthands.gap("16px"),
      display: "flex",
      flexWrap: "nowrap",
    },
  
    card: {
      marginRight: "16px",
      marginBottom: "16px",
      maxWidth: "100%",
      height: "fit-content",
      "&:hover": {
        boxShadow: "0 0 0 2px #605e5c",
      },
    },
  
    caption: {
      color: tokens.colorNeutralForeground3,
    },
  
    smallRadius: {
      ...shorthands.borderRadius(tokens.borderRadiusSmall),
    },
  
    grayBackground: {
        "&:hover": {
            cursor: "pointer",
        },
      backgroundColor: tokens.colorNeutralBackground3,
    },
  
    logoBadge: {
      ...shorthands.padding("5px"),
      ...shorthands.borderRadius(tokens.borderRadiusSmall),
      backgroundColor: "#FFF",
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    },
  });
  

  const ProjectCard = (props: CardProps) => {
    const styles = useStyles();

  return (
    <Link to="/editor">
    <Card className={styles.card} {...props}>
      
      <CardPreview
        className={styles.grayBackground}
        onClick={() => {}}
        logo={
          <img
            className={styles.logoBadge}
            src={resolveAsset("logo3.svg")}
            alt="Figma app logo"
          />
        }
      >
        <img
          className={styles.smallRadius}
          src={resolveAsset("office1.png")}
          alt="Presentation Preview"
        />
      </CardPreview>
      

      <CardHeader
        header={<Text weight="semibold">iOS App Prototype</Text>}
        description={
          <Caption1 className={styles.caption}>You created 53m ago</Caption1>
        }
        action={
            <Menu>
                <MenuTrigger>
                <Button
                    appearance="transparent"
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More actions"
                />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem icon={<DesktopEdit24Regular />}>Open </MenuItem>
                    <MenuItem icon={<Delete24Regular />}>Delete</MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
        }
      />
    </Card>
    </Link>
  );
};
    

function Home() {
    const styles = {
        position: "absolute",
        top: "57px",
        left: "289px",
        right: 0,
        bottom: 0,
        backgroundColor:"#141414",
        padding: "22px 22px",
        borderBottom: "1px solid #2b333b",
    };

    return ( <div>
        <div className="Home" style={styles}>
            <Title2>My Projects</Title2>
        <TabList  defaultSelectedValue="tab1" size="medium">
                    <Tab value="tab1" >Recents</Tab>
                    <Tab value="tab2" >Shared</Tab>
                    <Tab value="tab3">Archive</Tab>
                </TabList>
        <Divider appearance='subtle'/>
        <div style={{padding: "12px 0px", display: "flex", flexWrap: "wrap"}}>
        <ProjectCard/>
        <ProjectCard/>
        <ProjectCard/>
        <ProjectCard/>
        <ProjectCard/>
        <ProjectCard/>
        <ProjectCard/>
        <ProjectCard/>
        </div>
        <Divider appearance='subtle'/>
        </div>
    </div> );
}

export default Home;