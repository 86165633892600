import React, { Component } from 'react';
import {
    makeStyles,
    Toolbar,
    ToolbarButton,
    ToolbarDivider,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    ToolbarProps,
  } from "@fluentui/react-components";
  import {
    FontIncrease24Regular,
    Cube24Regular,
    TextFont24Regular,
    ArrowMove24Regular,
    MoreHorizontal24Filled,
    ArrowRotateClockwise24Regular,
    ArrowExpand24Regular,
  } from "@fluentui/react-icons";

  

class Left extends Component {
    state = { name: "React" } 
    
    render() { 

        const styles = {
            position: "absolute",
            top: "56px",
            bottom: 0,
            left: 0,
            width: "56px",
            backgroundColor:"#1c1c1c",
            padding: "4px",
            borderRight: "1px solid #303030",
            boxSizing: "border-box",
        };
        return (
            <div>
                <div className="Left" style={styles}>
                <Toolbar vertical aria-label="Default" style={{display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"}}>
                <ToolbarButton
                    aria-label="Increase Font Size"
                    appearance="primary"
                    icon={<ArrowMove24Regular />}
                />
                <ToolbarButton
                    aria-label="Decrease Font Size"
                    icon={<ArrowRotateClockwise24Regular />}
                />
                <ToolbarButton aria-label="Reset Font Size" icon={<ArrowExpand24Regular />} />
                <ToolbarDivider />
                </Toolbar>
                </div>
            </div>
        );
    }
}
 
export default Left;